import React from 'react';
import './PopUp.scss';

const PopUp = ({ open, message, clearContact }) => {
    return (
        open && 
        <div className="PopUp_1313">
            <div className="shadow" onClick = { clearContact }></div>
            <div className="modal__open">
                <span onClick = { clearContact }>&#10005;</span>
                <p className="modal--title">{ message }</p>
            </div>
        </div>
    );
};

export default PopUp;