import React, { Suspense, lazy} from 'react';
import './App.scss';

// libraries
import { Switch, Route, withRouter } from 'react-router-dom';
import ScrollUpButton from "react-scroll-up-button";

// componets
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

// components with lazy
const HomePage = lazy(() => import('./containers/HomePage/HomePage'));
const Products = lazy(() => import('./containers/Products/Products'));
const Services = lazy(() => import('./containers/Services/Services'));
const Offers = lazy(() => import('./containers/Offers/Offers'));
const ProductSingle = lazy(() => import('./containers/ProductSingle/ProductSingle'));
const ServiceSingle = lazy(() => import('./containers/ServiceSingle/ServiceSingle'));
const OfferSingle = lazy(() => import('./containers/OfferSingle/OfferSingle'));
const Board = lazy(() => import('./containers/Board/Board'));
const Staff = lazy(() => import('./containers/Staff/Staff'));
const Contact = lazy(() => import('./containers/Contact/Contact'));
const News = lazy(() => import('./containers/News/News'));
const NewSingle = lazy(() => import('./containers/NewSingle/NewSingle'));
const Partners = lazy(() => import('./containers/Partners/Partners'));
const CompanyProfile = lazy(() => import('./containers/CompanyProfile/CompanyProfile'));
const JoinUs = lazy(() => import('./containers/JoinUs/JoinUs'));
const JoinUsSingle = lazy(() => import('./containers/JoinUsSingle/JoinUsSingle'));
const References = lazy(() => import('./containers/References/References'));
const CostumerStories = lazy(() => import('./containers/CostumerStories/CostumerStories'));
const CostumerStorySingle = lazy(() => import('./containers/CostumerStorySingle/CostumerStorySingle'));
const Companies = lazy(() => import('./containers/Companies/Companies'));
const CodeEthics = lazy(() => import('./containers/CodeEthics/CodeEthics'));
const TermsConditions = lazy(() => import('./containers/TermsConditions/TermsConditions'));
const Timeline = lazy(() => import('./containers/Timeline/Timeline'));
const Page404 = lazy(() => import('./containers/Page404/Page404'));

// harrisia
const HarrisiaHomePage = lazy(() => import('./containers/HarrisiaHomePage/HarrisiaHomePage'));
const CbsHomePage = lazy(() => import('./containers/CbsHomePage/CbsHomePage'));

const App = (props) => {
  
  // route location
  let route = props.location.pathname.split('/')[1];
  let path = null;
  if(route !== 'harrisia' && route !== 'cbs'){
    route = 'cac';
    path = "";
  }
  else if(route ==='harrisia'){
    route = "harrisia";
    path = "/harrisia";
  }
  else if(route ==='cbs'){
    route = "cbs";
    path = "/cbs";
  }

  let lang = window.localStorage.getItem('lang');
  if(!lang){
    window.localStorage.setItem('lang', 'sq');
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <Navbar route = { route } path = { path }/>
          <div className="app--body">
            <Suspense fallback={
                <div className={`spinner__wrapper spinner__wrapper__${route}`}>
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>}>
              <Switch>
                <Route exact path="/product/:slug" render={ (props) => <ProductSingle { ...props } route = "cac" path=""/>}/>
                <Route exact path="/service/:slug" render={ (props) => <ServiceSingle { ...props } route = "cac" path=""/>}/>
                <Route exact path="/offer/:slug" render={ (props) => <OfferSingle { ...props } route = "cac" path=""/>}/>
                <Route exact path="/board" render={ (props) => <Board { ...props } route = "cac" path=""/>}/>
                <Route exact path="/staff" render={ (props) => <Staff { ...props } route = "cac" path=""/>}/>
                <Route exact path="/products" render={ (props) => <Products { ...props } route = "cac" path=""/>}/>
                <Route exact path="/services" render={ (props) => <Services { ...props } route = "cac" path=""/>}/>
                <Route exact path="/offers" render={ (props) => <Offers { ...props } route = "cac" path=""/>}/>
                <Route exact path="/contact" render={ (props) => <Contact { ...props } route = "cac" path=""/>}/>
                <Route exact path="/partners" render={ (props) => <Partners { ...props } route = "cac" path=""/>}/>
                <Route exact path="/news" render={ (props) => <News { ...props } route = "cac" path=""/>}/>
                <Route exact path="/news/:slug" render={ (props) => <NewSingle { ...props } route = "cac" path=""/>}/>
                <Route exact path="/company-profile" render={ (props) => <CompanyProfile { ...props } route = "cac" path=""/>}/>
                <Route exact path="/costumer-stories" render={ (props) => <CostumerStories { ...props } route = "cac" path=""/>}/>
                <Route exact path="/costumer-stories/:slug" render={ (props) => <CostumerStorySingle { ...props } route = "cac" path=""/>}/>
                <Route exact path="/join-us" render={ (props) => <JoinUs { ...props } route = "cac" path=""/>}/>
                <Route exact path="/join-us/:slug" render={ (props) => <JoinUsSingle { ...props } route = "cac" path=""/>}/>
                <Route exact path="/references" render={ (props) => <References { ...props } route = "cac" path=""/>}/>
                <Route exact path="/companies" render={ (props) => <Companies { ...props } route = "cac" path=""/>}/>
                <Route exact path="/terms-and-conditions" render={ (props) => <TermsConditions { ...props } route = "cac" path=""/>}/>
                <Route exact path="/code-of-ethics" render={ (props) => <CodeEthics { ...props } route = "cac" path=""/>}/>
                <Route exact path="/timeline" render={ (props) => <Timeline { ...props } route = "cac" path=""/>}/>

                <Route exact path="/harrisia" render = { (props) => <HarrisiaHomePage {...props } route = "harrisia" path="/harrisia" /> }/>
                <Route exact path="/harrisia/products" render={ (props) => <Products { ...props } route = "harrisia" path="/harrisia"/>}/>
                <Route exact path="/harrisia/references" render = { (props) => <References {...props } route = "harrisia" path="/harrisia"/> }/>
                <Route exact path="/harrisia/product/:slug" render={ (props) => <ProductSingle { ...props } route = "harrisia" path="/harrisia"/>}/>
                <Route exact path="/harrisia/services" render={ (props) => <Services { ...props } route = "harrisia" path="/harrisia"/>}/>
                <Route exact path="/harrisia/service/:slug" render={ (props) => <ServiceSingle { ...props } route = "harrisia" path="/harrisia"/>}/>
                <Route exact path="/harrisia/offers" render={ (props) => <Offers { ...props } route = "harrisia" path="/harrisia"/>}/>
                <Route exact path="/harrisia/offer/:slug" render={ (props) => <OfferSingle { ...props } route = "harrisia" path="/harrisia"/>}/>
                <Route exact path="/harrisia/board" render={ (props) => <Board { ...props } route = "harrisia" path="/harrisia"/>}/>
                <Route exact path="/harrisia/staff" render={ (props) => <Staff { ...props } route = "harrisia" path="/harrisia"/>}/>
                <Route exact path="/harrisia/join-us" render={ (props) => <JoinUs { ...props } route = "harrisia" path="/harrisia"/>}/>
                <Route exact path="/harrisia/join-us/:slug" render={ (props) => <JoinUsSingle { ...props } route = "harrisia" path="/harrisia"/>}/>
                <Route exact path="/harrisia/costumer-stories" render={ (props) => <CostumerStories { ...props } route = "harrisia" path="/harrisia"/>}/>
                <Route exact path="/harrisia/costumer-stories/:slug" render={ (props) => <CostumerStorySingle { ...props } route = "harrisia" path="/harrisia"/>}/>
                <Route exact path="/harrisia/news" render={ (props) => <News { ...props } route = "harrisia" path="/harrisia"/>}/>
                <Route exact path="/harrisia/news/:slug" render={ (props) => <NewSingle { ...props } route = "harrisia" path="/harrisia"/>}/>
                <Route exact path="/harrisia/terms-and-conditions" render={ (props) => <TermsConditions { ...props } route = "harrisia" path="/harrisia"/>}/>
                <Route exact path="/harrisia/code-of-ethics" render={ (props) => <CodeEthics { ...props } route = "harrisia" path="/harrisia"/>}/>
                <Route exact path="/harrisia/companies" render={ (props) => <Companies { ...props } route = "harrisia" path="/harrisia"/>}/>
                <Route exact path="/harrisia/company-profile" render={ (props) => <CompanyProfile { ...props } route = "harrisia" path="/harrisia"/>}/>
                <Route exact path="/harrisia/contact" render={ (props) => <Contact { ...props } route = "harrisia" path="/harrisia"/>}/>
                <Route exact path="/harrisia/partners" render={ (props) => <Partners { ...props } route = "harrisia" path="/harrisia"/>}/>
                <Route exact path="/harrisia/timeline" render={ (props) => <Timeline { ...props } route = "harrisia" path="/harrisia"/>}/>
                
                <Route exact path="/cbs" render = { (props) => <CbsHomePage {...props } route = "cbs" path="/cbs" /> }/>
                <Route exact path="/cbs/products" render={ (props) => <Products { ...props } route = "cbs" path="/cbs"/>}/>
                <Route exact path="/cbs/references" render = { (props) => <References {...props } route = "cbs" path="/cbs"/> }/>
                <Route exact path="/cbs/product/:slug" render={ (props) => <ProductSingle { ...props } route = "cbs" path="/cbs"/>}/>
                <Route exact path="/cbs/services" render={ (props) => <Services { ...props } route = "cbs" path="/cbs"/>}/>
                <Route exact path="/cbs/service/:slug" render={ (props) => <ServiceSingle { ...props } route = "cbs" path="/cbs"/>}/>
                <Route exact path="/cbs/offers" render={ (props) => <Offers { ...props } route = "cbs" path="/cbs"/>}/>
                <Route exact path="/cbs/offer/:slug" render={ (props) => <OfferSingle { ...props } route = "cbs" path="/cbs"/>}/>
                <Route exact path="/cbs/board" render={ (props) => <Board { ...props } route = "cbs" path="/cbs"/>}/>
                <Route exact path="/cbs/staff" render={ (props) => <Staff { ...props } route = "cbs" path="/cbs"/>}/>
                <Route exact path="/cbs/join-us" render={ (props) => <JoinUs { ...props } route = "cbs" path="/cbs"/>}/>
                <Route exact path="/cbs/join-us/:slug" render={ (props) => <JoinUsSingle { ...props } route = "cbs" path="/cbs"/>}/>
                <Route exact path="/cbs/costumer-stories" render={ (props) => <CostumerStories { ...props } route = "cbs" path="/cbs"/>}/>
                <Route exact path="/cbs/costumer-stories/:slug" render={ (props) => <CostumerStorySingle { ...props } route = "cbs" path="/cbs"/>}/>
                <Route exact path="/cbs/news" render={ (props) => <News { ...props } route = "cbs" path="/cbs"/>}/>
                <Route exact path="/cbs/news/:slug" render={ (props) => <NewSingle { ...props } route = "cbs" path="/cbs"/>}/>
                <Route exact path="/cbs/terms-and-conditions" render={ (props) => <TermsConditions { ...props } route = "cbs" path="/cbs"/>}/>
                <Route exact path="/cbs/code-of-ethics" render={ (props) => <CodeEthics { ...props } route = "cbs" path="/cbs"/>}/>
                <Route exact path="/cbs/companies" render={ (props) => <Companies { ...props } route = "cbs" path="/cbs"/>}/>
                <Route exact path="/cbs/company-profile" render={ (props) => <CompanyProfile { ...props } route = "cbs" path="/cbs"/>}/>
                <Route exact path="/cbs/contact" render={ (props) => <Contact { ...props } route = "cbs" path="/cbs"/>}/>
                <Route exact path="/cbs/partners" render={ (props) => <Partners { ...props } route = "cbs" path="/cbs"/>}/>
                <Route exact path="/cbs/timeline" render={ (props) => <Timeline { ...props } route = "cbs" path="/cbs"/>}/>

                <Route exact path="/" render ={ (props) => <HomePage { ...props } route = "cac" path=""/>}/>
                <Route component={Page404}/>
              </Switch>
            </Suspense>
          </div>
          <Footer route = { route }/>
          <ScrollUpButton
            StopPosition={0}
            ShowAtPosition={150}
            EasingType='easeOutCubic'
            AnimationDuration={500}
            ContainerClassName={`${route}_ScrollUpButton__Container`}
            TransitionClassName='ScrollUpButton__Toggled'
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(App);