import React, { useState, useEffect } from 'react';
import './Footer.scss';

// libraries
import renderHTML from 'react-render-html';
import { NavLink } from 'react-router-dom';
import * as http from '../../Axios/HttpRequests';

// components
import Newsletter from '../Newsletter/Newsletter';

const Footer = (props) => {
    
    const [ footerData, setFooterData ] = useState({});

    useEffect(() => {
        http.Footer(props.route)
            .then(res => setFooterData(res.data))
            .catch(err => console.log(err))
    }, [props.route]);

    return (
        <div 
            className="row justify-content-center FooterWrapper"
            style={{ 
                background: `url(${footerData.background_image}) no-repeat center /100% 100%`
            }}
        >
            <div className="col-lg-10 Footer">
                <div className="row">
                    <div className="col-lg-4 f--boxes">
                        <img 
                            className="logo" 
                            src = {`${process.env.PUBLIC_URL}/images/wh_${props.route}.png`}
                            alt="cacttus"
                        />
                        <Newsletter 
                            route={ props.route }
                            description = { footerData.newsletter_description }
                            placeholder = { footerData.newsletter_placeholder }
                            message = { footerData.newsletter_message }
                        />
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-2 f--boxes">
                        <p className="f--box--title">{footerData.quick_links_title}</p>
                        <ul>
                            {
                                footerData.quick_links ?
                                    footerData.quick_links.map((link, index) => {
                                        return (
                                            <li key={index}>
                                                <a 
                                                    href={link.link}
                                                    target="_blank" 
                                                    rel="noopener noreferrer" 
                                                >
                                                    {link.title}
                                                </a>
                                            </li>
                                        )
                                    }) : null
                            }
                        </ul>
                    </div>
                    <div className="col-lg-2 f--boxes">
                         <p className="f--box--title">{footerData.contact_info_title}</p>
                        {
                            footerData.contact_info_content ? renderHTML(footerData.contact_info_content) : ''
                        }
                    </div>
                    <div className="col-lg-2 f--boxes">
                        <p className="f--box--title">{footerData.social_media_title}</p>
                        <ul>
                            {
                                footerData.social_media_links ?
                                    footerData.social_media_links.map((link, index) => {
                                        return (
                                            <li key={index}>
                                                <a 
                                                    href={link.link}
                                                    target="_blank" 
                                                    rel="noopener noreferrer" 
                                                >
                                                    {link.title}
                                                </a>
                                            </li>
                                        )
                                    }) : null
                            }
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 footer--bottom">
                        <p>{ footerData.copyright }</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;