import Axios from './Axios';

let lang = localStorage.getItem("language");

export const getLanguage = () => {
    let gotLang = localStorage.getItem("lang");

    if(gotLang){
        lang = gotLang;
    }
    else
    {
        lang = 'sq';
    }
}

getLanguage();


//general

// get as much as you want
export const getNPosts = (departament, number) => {
    return Axios.get(`${departament}/posts/newsFirstPage/${lang}/${number}`);
}

export const Home = (departament) => {
    return Axios.get(`${departament}/home/${lang}`)
}

export const Header = (departament) => {
    return Axios.get(`${departament}/header/${lang}`)
}

export const Paramters = (departament) => {
    return Axios.get(`${departament}/parameterspage/${lang}`);
}

export const Footer = (departament) => {
    return Axios.get(`${departament}/footer/${lang}`)
}

export const ImportantNews = (departament) => {
    return Axios.get(`${departament}/news/important/${lang}`)
}

export const News = (departament, name, page) => {
    return Axios.get(`${departament}/search?name=${name}&lang=${lang}&page=${page}`)
}

export const Offers = (departament) => {
    return Axios.get(`${departament}/offers/${lang}`)
}

export const PostsOffers = (departament) => {
    return Axios.get(`${departament}/posts/offers/${lang}`)
}

//get single post
export const GetSinglePost = (departament, slug) => {
    return Axios.get(`${departament}/posts/new/${lang}/${slug}`)
}

//Products and Services
export const Products = (departament) => {
    return Axios.get(`${departament}/products/${lang}`)
}

export const PostsProducts = (departament) => {
    return Axios.get(`${departament}/posts/products/${lang}`)
}

export const Services = (departament) => {
    return Axios.get(`${departament}/services/${lang}`)
}

export const PostsServices = (departament) => {
    return Axios.get(`${departament}/posts/services/${lang}`)
}

export const Partners = (departament) => {
    return Axios.get(`${departament}/partners/${lang}`)
}

export const PostsPartners = (departament) => {
    return Axios.get(`${departament}/posts/partners/${lang}`)
}

//single products, services, offers

export const SingleProduct = (departament, slag) => {
    return Axios.get(`${departament}/posts/singleproduct/${lang}/${slag}`)
}

export const SingleService = (departament, slag) => {
    return Axios.get(`${departament}/posts/singleservice/${lang}/${slag}`)
}

export const SingleOffer = (departament, slag) => {
    return Axios.get(`${departament}/posts/singleoffer/${lang}/${slag}`)
}

export const NewsSuccess = (departament, month, year) => {
    return Axios.get(`${departament}/posts/news-success/${lang}/${year}/${month}`)
}

//company
export const CompanyProfile = (departament) => {
    return Axios.get(`${departament}/companyprofile/${lang}`)
}

export const Career = (departament) => {
    return Axios.get(`${departament}/career/${lang}`)
}

export const CodeOfEthics = (departament) => {
    return Axios.get(`${departament}/codeofEthics/${lang}`)
}

export const Terms = (departament) => {
    return Axios.get(`${departament}/termsConditions/${lang}`)
}

//contact
export const Contact = (departament) => {
    return Axios.get(`${departament}/contact/${lang}`)
}

export const ContactSend = (departament, data) => {
    return Axios.post(`${departament}/sendEmail/`, data)
}

export const Subscribe = (departament, email) => {
    // return Axios.post(`${departament}/subscribe/${email}`)
    return Axios.post(`${departament}/subscribe?email=${email}`)
}

export const References = (departament) => {
    return Axios.get(`${departament}/reference/${lang}`)
}

// job page and job posts
export const Jobs = (departament) => {
    return Axios.get(`${departament}/posts/jobs/${lang}`)
}

export const JobPage = (departament) => {
    return Axios.get(`${departament}/jobs/${lang}`)
}

export const SingleJob = (departament, slug) => {
    return Axios.get(`${departament}/posts/singlejob/${lang}/${slug}`)
}

export const getTestimonial = (departament,slag,post_type) => {
    return Axios.get(`${departament}/testimonials/${lang}/${slag}/${post_type}`)
}

export const Navbar = (departament) => {
    return Axios.get(`${departament}/menu/${lang}`)
}

export const menuImage = (departament) => {
    return Axios.get(`${departament}/menuImage/${lang}`)
}

export const SendCV = (departament, data) => {
    return Axios.post(`${departament}/sendCV`, data);
}

// Site Map 
export const SiteMap = (departament) => {
    return Axios.get(`${departament}/sitemap/${lang}`)
}

//all categories
export const SearchAllCategories = (departament, name) => {
    return Axios.get(`${departament}/search/?name=${name}&lang=${lang}`)
}

//ourcompanies
export const OurCompanies = (departament) => {
    return Axios.get(`${departament}/ourcompanies/${lang}`)
}

//ourcomapnies
export const noNewsMessage = (departament) => {
    return Axios.get(`${departament}/news/${lang}`)
}

//costumer stories 
export const CostumerStories = (departament) => {
    return Axios.get(`${departament}/costumerstories/${lang}`)
}

//costumer stories list
export const CostumerStoriesList = (departament) => {
    return Axios.get(`${departament}/posts/costumerstories/${lang}`)
}

//costumer stories list
export const CostumerStoriesSingle = (departament, slug) => {
    return Axios.get(`${departament}/posts/singlecostumerstories/${lang}/${slug}`)
}

export const Staff = (departament) => {
    return Axios.get(`${departament}/staff/${lang}`);
}

export const Board = (departament) => {
    return Axios.get(`${departament}/board/${lang}`);
}

export const Timeline = (departament) => {
    return Axios.get(`${departament}/timeline/${lang}`);
}

export const GetSixNews = (departament) => {
    return Axios.get(`${departament}/posts/newsFirstPage/${lang}/6`);
}

export const getAllNews = (departament, page) => {
    return Axios.get(`${departament}/allNews?page=${page}&lang=${lang}`);
}
