import Axios from 'axios';

const axiosInstance = Axios.create({
    baseURL: 'https://cacttus.com/api-new/wp-json/'
    // baseURL: 'http://hotspotcacttus.com/api/wp-json/'
    // baseURL: 'http://localhost/api/wp-json/'
});

export default axiosInstance;

//  cac
//  harrisia
//  cbs 