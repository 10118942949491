import React, { useState, useEffect, useRef } from 'react';
import './Navbar.scss';

// libraries
import { NavLink, withRouter } from 'react-router-dom';
import * as http from '../../Axios/HttpRequests';

function App(props) {

  const input = useRef(null);
  const [ navbar, setNavbar ] = useState({ 
      scrolled: false,
      menuOpen: false,
      mainLinks: null,
      subLinks: null,
      subLinksToShow: null,
      menuImage: null,
      imageShow: null
  });

  const [ sidebar, setSidebar ] = useState({
      open: false,
      index: null
  })

  useEffect(() => {
      const getNavbar = http.Navbar(props.route);
      const getMenu = http.menuImage(props.route);

      Promise.all([getNavbar, getMenu])
        .then(response => {
          const [res, menuImage] = response;
          let mainLinks = [];
          let subLinks = [];
          res.data.forEach((el) => {
            el.menu_item_parent === '0' ? mainLinks.push(el) : subLinks.push(el);
          });
          setNavbar({ ...navbar, mainLinks: mainLinks, subLinks: subLinks, menuImage: menuImage.data.images });
        })
        .catch(error => console.log(error));

  }, [props.route]);

  // close sidebar on each url change
  useEffect(() => {
      setSidebar({...sidebar, open: false });
  }, [props.location]);

  useEffect(() => {
      window.addEventListener('scroll', handleScroll, { passive: true });
      return () => {
          window.removeEventListener('scroll', handleScroll);
      }
  }, [navbar]);

  useEffect(() => { 
    sidebar.open ? document.getElementsByTagName('body')[0].style.overflow = 'hidden' : document.getElementsByTagName('body')[0].style.overflow = 'auto';
  }, [ sidebar.open ])

  const handleScroll = () => {
      const ST = parseInt(window.pageYOffset);
      ST > 50 ? setNavbar({ ...navbar, scrolled: true }) : setNavbar({ ...navbar, scrolled: false });
  }
  
  const hoverItem = (id, post_title) => {
      let children = navbar.subLinks.filter((el) => +el.menu_item_parent === id);
      console.log(post_title);
      let imageShow = navbar.menuImage.find((el, index) => {
        return el.menu_name_en.includes(post_title) || el.menu_name_sq.includes(post_title) ;
      });
      setNavbar({ ...navbar, subLinksToShow: children, menuOpen: true, imageShow: imageShow ? imageShow : {} })
  }

  let path = null;
  const pathname = props.location.pathname;
  if(
    !(pathname.includes('/') && pathname.length == 1) &&
    !(pathname.includes('/cbs') && pathname.length == 4) &&
    !(pathname.includes('/harrisia') && pathname.length == 9) &&
    !pathname.includes('/product') &&
    !pathname.includes('/service') && 
    !pathname.includes('/partners') &&
    !pathname.includes('/offer') &&
    !pathname.includes('/company-profile')  &&
    !pathname.includes('/costumer-stories')  &&
    !pathname.includes('/reference') &&
    (!pathname.includes('/join-us') || (pathname.includes('/join-us') && pathname.length > 8))
  )
  {
      path = true;
  }
  else
  {
      path = false;
  }

  const search = (e) => {
      e.preventDefault();
      input.current.blur();
      if(e.target.elements.search.value){
        let url = `${props.path}/news?search=${e.target.elements.search.value}&page=1`;
        props.history.push(url);
      }
  }

  const changeLanguage = (ln) => {
    let lang = window.localStorage.getItem('lang');
    if(ln !== lang){
      window.localStorage.setItem('lang', ln);
      window.location.reload();
    }
  }

  return (
      <div className="row c-f" onMouseLeave = { () => setNavbar({ ...navbar, menuOpen: false })}>
        <div className={`col-lg-12 Navbar--Fixed  ${ (navbar.scrolled || path || navbar.menuOpen || sidebar.open ) ? `${sidebar.open ? 'bg_wno' : 'bg-w'}` : 'bg-t' }`}>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="Navbar">
                <div className="nav--logo">
                    <NavLink to={ props.path }>
                        <img 
                            className="nav--img"
                            src={`${process.env.PUBLIC_URL}/images/${(navbar.scrolled || path || navbar.menuOpen || sidebar.open ) ? 'bl' : 'wh'}_${props.route}.png`}
                            alt="cacttus"
                        />
                    </NavLink>
                </div>
                <div className="nav--menu">
                  <ul>
                    { navbar.mainLinks?.map((el, index) => 
                      <li key = { index }>
                        <NavLink 
                          to={ el.url }
                          onMouseOver = { () => !el.url.includes('/contact') ? hoverItem(el.ID, el.post_title) : '' }
                          className={`${props.route}_nav-item ${el.url.includes('/contact') ? `contact--us contact--us--${props.route}` : ''}`}
                          activeClassName={ props.location.pathname.includes(el.url) ? `${props.route}_active` : '' }
                        >
                          { el.post_title }
                        </NavLink>
                      </li> 
                    )}
                  </ul>
                </div>
                <button 
                  className={ `toggle--button ${ (path || navbar.scrolled || sidebar.open) ? `${props.route}_btn--gr` : 'btn--wh' }`}
                  onClick = { () => setSidebar({ ...sidebar, open: !sidebar.open })}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
              </div>
            </div>
            <div className="languages">
              <button className={`lang__button ${window.localStorage.getItem('lang') == 'en' && "lang__active"}`} onClick = { () => changeLanguage('en')}>EN</button>
              <button className={`lang__button ${window.localStorage.getItem('lang') == 'sq' && "lang__active"}`} onClick = { () => changeLanguage('sq')}>SQ</button>
            </div>
          </div>
        </div>
        <div className={`Dropdown ${ navbar.menuOpen && 'dr__down'}`}  
          onMouseOver = { () => setNavbar({ ...navbar, menuOpen: true })}
        >
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="row">
                  <div className="col-lg-6">
                    <NavLink to={ navbar.imageShow?.uri || '' }>
                      <img 
                        className="dropdown--image"
                        src={ navbar.imageShow?.image }
                        alt = { navbar.menuImage?.image }
                      />
                    </NavLink>
                  </div>
                  <div className="col-lg-6">
                    <ul>
                      { navbar.subLinksToShow?.map((el, index) => 
                          <li key = { index }>
                            <NavLink 
                              key = { index } 
                              to={ el.url }
                              className= { `${props.route}__dropdown__item`}
                              activeClassName= { `${props.route}__dropdown__item_act`}
                            >
                              { el.post_title }
                            </NavLink>
                          </li> 
                      )}
                      
                    </ul>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div className={ `Sidebar ${sidebar.open && 'sr__left'}`}>
          <div className="search__here">
            <form onSubmit = { search }>
                <i className="fa fa-search search__icon"/>
                <input type="search" className="search__input" placeholder="Search" name="search" ref={ input }/>
                <button className={`${props.route}__button`}><i className="fas fa-search"/></button>
            </form>
          </div>    
          <div className="languages">
            <button className={`lang__button ${window.localStorage.getItem('lang') == 'en' && "lang__active"}`} onClick = { () => changeLanguage('en')}>EN</button>
            <button className={`lang__button ${window.localStorage.getItem('lang') == 'sq' && "lang__active"}`} onClick = { () => changeLanguage('sq')}>SQ</button>
          </div>
          <ul>
            { navbar.mainLinks?.map((el, index) => 
              <li key = { index }>
                <NavLink 
                  key = { index } 
                  to={ el.url }
                  className= { sidebar.index === index ? `${props.route}__active__item` : '' }
                >
                  { el.post_title }
                </NavLink>
                <i className = {`fas fa-chevron-${sidebar.index === index ? 'up' : 'down'}`} onClick = { () => setSidebar({ ...sidebar, index: sidebar.index === index ? null : index }) }/>
                {
                  sidebar.index === index &&
                  <ul>
                    { navbar.subLinks.filter((ei) => +ei.menu_item_parent === el.ID).map((e, index) => 
                      <li key = { index }>
                        <NavLink 
                          key = { index } 
                          to={ e.url }
                        >
                          { e.post_title }
                        </NavLink>
                      </li> 
                    )}
                  </ul>
                }
              </li> 
            )}
          </ul>
        </div>
      </div>
  );
}

export default withRouter(App);
