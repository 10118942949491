import React, { useState } from 'react';
import './Newsletter.scss';

import { Subscribe } from '../../Axios/HttpRequests';

// components
import PopUp from '../../components/PopUp/PopUp';

const Newsletter = (props) => {

    const [ data, setData ] = useState(null);
    const [ modal, setModal ] = useState({ open: false, message: null });

    const emailHandler = e => {
        setData(e.target.value);
    }

    const submitData = e => {
        e.preventDefault();
        let isEmail = data.includes('@') && data.includes('.');
        if(data && isEmail){
            Subscribe(props.route, data)
                .then(res => {
                    if(res.data.id){
                        setModal({ open: true, message: 'Thanks for subscribing' });
                    }
                    else if(res.data.title){
                        setModal({ open: true, message: res.data.title });
                    }
                })
                .catch(error => console.log(error))
        }
    }

    const clearContact = () => {
        setModal({ message: null , open: false });
        setData(null);
    }

    return(
        <div className="row">
            <PopUp 
                open = { modal.open }
                message = { modal.message }
                clearContact = { clearContact }
            /> 
            <div className="col-lg-12 NewsletterWrapper">
                <div className="row justify-content-center">
                    <div className="col-lg-10 Newsletter">
                        <p className="title">{ props.description }</p>
                        <form onSubmit = { submitData }>
                            <input 
                                type="email" 
                                placeholder={ props.placeholder || '' } 
                                name = "email" 
                                onChange = { emailHandler } 
                                value = { data || '' }
                                required
                            />
                            <button className={`${props.route}_btn_arrow`}>
                                <i className="fas fa-location-arrow"></i>
                            </button>
                        </form> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Newsletter;